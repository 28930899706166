import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { Flex, Img, Paragraph, Title } from '@components';
import { formatCurrency } from '@utils/shopify';
import { NormalizedVariant } from '@ts/index';
import { LOCALE_CODES } from '@constants';
import styles from './MyTops.module.scss';
import { useTranslation } from '@utils/index';

const Trash = dynamic(() => import('@components').then(mod => mod.Trash));

const PLACEHOLDER_LIST_LENGTH = 3;

type MyTopsProps = {
	tops: {
		[key: string]: NormalizedVariant;
	};
	onTrashClick?: (top: NormalizedVariant) => void;
	placeholderListLength?: number;
	sizeTops?: 'normal' | 'small';
	color?: 'white' | 'gray';
	type?: 'build-flow' | 'bundle';
};

const getTranslatedTexts = translator => {
	return {
		addAtTopFrame: translator('add-a-top-frame'),
	};
};

const MyTops = ({
	tops,
	onTrashClick,
	placeholderListLength = PLACEHOLDER_LIST_LENGTH,
	sizeTops = 'small',
	color = 'gray',
	type = 'build-flow',
}: MyTopsProps) => {
	const { locale } = useRouter();
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<ul className={cn(styles.list, { [styles['list--white']]: color === 'white' })}>
			{Object.keys(tops).map((topHandle: string) => {
				return (
					<li key={`${tops[topHandle].handle}-list-item`} className={styles['list-item']}>
						<Flex align='center' justify='start' fullWidth gap={3}>
							<Flex align='center' gap={3} fullWidth>
								<img
									className={styles['list-item-image']}
									src={tops[topHandle].image.url}
									alt={tops[topHandle].name}
									width={64}
									height={32}
								/>
								<Title>{tops[topHandle].name}</Title>
							</Flex>

							<div className={styles['list-item-delete']}>
								<Trash
									callback={() => {
										onTrashClick(tops[topHandle]);
									}}
									dataTags={type === 'bundle' ? { 'data-delete-top-byob': tops[topHandle].name } : null}
								/>
							</div>
							<Paragraph>
								{formatCurrency(
									{
										amount: tops[topHandle].price.amount,
										currencyCode: tops[topHandle].price.currencyCode,
										locale,
									},
									showCurr
								)}
							</Paragraph>
						</Flex>
					</li>
				);
			})}
			{Object.keys(tops).length < placeholderListLength &&
				new Array(placeholderListLength - Object.keys(tops).length).fill(true).map((_, index) => (
					<li
						key={`placeholder-${index}`}
						className={cn(styles['list-placeholder'], {
							[styles['list-placeholder--normal']]: sizeTops === 'normal',
						})}
					>
						<Flex align='center' justify='start' fullWidth gap={3}>
							<Img
								className={styles['list-placeholder-image']}
								src={'/empty-selection.gif'}
								noSrcset
								alt='empty selection'
								width={64}
								height={32}
							/>
							<Title>{translations.addAtTopFrame}</Title>
						</Flex>
					</li>
				))}
		</ul>
	);
};

export default MyTops;
